<template>
  <v-container>
    <v-container>
      <v-form ref="searchCondition">
        <v-row justify="center">
          検索条件
        </v-row>
        <v-row justify="center">
          <v-col md="4">
            <ctk-date-time-picker range only-date v-model="searchDateRange" :format="dateFormat" :formatted="dateFormat" label="検索範囲の業務日付（翌日5時までを当日として計上）" :rules="rules.notNull" />
          </v-col>
          <v-col>
            <v-btn :disabled="disableBtn" @click="search">検索</v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
    <v-container>
      <v-row justify="center">
          商品一覧
      </v-row>
      <v-row justify="center">
        <v-data-table dense multi-sort :headers="headers" :items="stocks" :options="options">
          <template v-slot:item.color="{ item: {color} }">
            {{!color ? "-" : color}}
          </template>
          <template v-slot:item.size="{ item: {size} }">
            {{!size ? "-" : size}}
          </template>
          <template v-slot:item.itemTopImagePath="{ item: {itemTopImagePath} }">
            <v-img :src="itemTopImagePath" max-height="80" max-width="80" />
          </template>
        </v-data-table>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
  import axios from 'axios'
  import firebaseStorageUtil from '../utils/firebase_storage_util'
  import CtkDateTimePicker from 'vue-ctk-date-time-picker'
  import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
  import DateUtils from '../utils/date_utils.js'
  import ValidationRules from '../consts/validation_rules.js'
  import Endpoints from '../consts/api_endpoint_map.js'

  export default {
    name: "get-stock-list",
    components: {
      CtkDateTimePicker
    },
    data () {
      return {
        // cache 
        itemStocksCache: [],
        itemStocksMapCache: {},
        // data-table
        headers: [
          {text: "受取日付", value: "receiveDatetime"},
          {text: "商品画像", value: "itemTopImagePath"},
          {text: "ユニーク商品名", value: "uniqueItemName"},
          {text: "数量", value: "quantity"},
          {text: "在庫数", value: "stockQuantity"},
          {text: "通貨レート", value: "exchangeRate"},
          {text: "仕入通貨価格", value: "purchaseCurrencyPrice"},
          {text: "価格", value: "price"},
          // {text: "仕入通貨買付手数料（一品）", value: "purchaseCurrencyBuyingCommission"},
          // {text: "仕入通貨検品手数料（一品）", value: "purchaseCurrencyInspectionFee"},
          {text: "送料", value: "shippingCostPerItem"},
          {text: "関税", value: "taxAmountPerItem"},
          {text: "商品原価", value: "itemCost"},
        ],
        stocks: [],
        options: {
          itemsPerPage: 30
        },
        disableBtn: false,
        // search condition
        dateFormat: "YYYY-MM-DD",
        sufficientDateRange: undefined,
        searchDateRange: undefined,
        rules: ValidationRules,
      }
    },
    async created () {
      // item image url
      await axios.get(Endpoints.getCacheItemStocksEndpoint)
        .then(res => {
          this.itemStocksCache = res.data
        })
        .catch(err => {
          console.log(err)
        })
      await axios.get(Endpoints.getCacheItemStocksMapEndpoint)
        .then(res => {
          this.itemStocksMapCache = res.data
        })
        .catch(err => {
          console.log(err)
        })
      var item
      // for(item of this.itemStocksCache){
      //   item.itemTopImagePath = await firebaseStorageUtil.getImgUrl(item.itemTopImagePath) 
      // }
      // imageUrlの取得が、 v-data-table の slot では対応できない（awaitできない）ため、こちらで変換する
      for(var key in this.itemStocksMapCache){
        item = this.itemStocksMapCache[key]
        item.itemTopImagePath = await firebaseStorageUtil.getImgUrl(item.itemTopImagePath) 
      }
      console.log("get firebase url done")
      // init condition
      this.setInitialDateRange()
      // stock list
      await this.search()
    },
    methods: {
      setInitialDateRange(){
        // set initial date
        const now = new Date()
        const end = DateUtils.toDatetime(now)
        now.setMonth(now.getMonth() - 1)
        const start = DateUtils.toDatetime(now)
        this.sufficientDateRange = {start: start, end: end}
        this.searchDateRange = this.sufficientDateRange
      },
      async search(){
        const res = await axios.get(Endpoints.getStockListEndpoint + 
          `?receiveDateFrom=${this.searchDateRange.start}&receiveDateTo=${this.searchDateRange.end}`)
        if(res.status === 200){
          const stocks = res.data.responseBody.stockList
          for(var stock of stocks){
            stock.itemTopImagePath = this.itemStocksMapCache[stock.itemId].itemTopImagePath 
          }
          this.stocks = stocks
        } else {
          console.log(`error : res.status is ${res.status}`)
          console.log(res)
          return []
        }
      },
    }
  }
</script>

<style>
</style>
